const cookieProIdDomain =
  process.env.REACT_APP_COOKIE_PRO_ID_DOMAIN ||
  "c03cb87e-212e-4a4f-8f03-c11e0e7ff1db-test";

function init() {
  const cookieProScript = document.getElementById("cookie-pro-script");
  if (!cookieProScript) {
    const script = document.createElement("script");
    script.src =
      "https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js";
    script.setAttribute("id", "cookie-pro-script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("charset", "UTF-8");
    script.setAttribute("data-domain-script", cookieProIdDomain);
    document.head.appendChild(script);
  }
}

export default {
  init,
};
